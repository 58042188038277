import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { FormGroup, FormControlLabel, Switch } from '@mui/material'

export const CreateAgendaItemDialog = ({ addItem }: any): any => {
  const [open, setOpen] = React.useState(false)
  const [topic, setTopic] = React.useState(false)
  const [description, setDescription] = React.useState(false)
  const [speaker, setSpeaker] = React.useState(false)
  const [voting, setVoting] = React.useState(false)

  const editTopic = (e: any): void => setTopic(e.target.value)
  const editDescription = (e: any): void => setDescription(e.target.value)
  const editSpeaker = (e: any): void => setSpeaker(e.target.value)
  const editVoting = (e: any): void => {
    setVoting(e.target.value === 'on')
  }

  const handleClickOpen = (): any => {
    setOpen(true)
  }

  const handleClose = (): any => {
    addItem({
      topic,
      description,
      speaker,
      voting,
    })
    setOpen(false)
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Agenda Item
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Agenda Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="topic"
            label="Topic"
            fullWidth
            variant="outlined"
            onChange={editTopic}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            fullWidth
            variant="outlined"
            multiline
            onChange={editDescription}
          />
          <TextField
            margin="dense"
            id="speaker"
            label="Speaker"
            type="email"
            fullWidth
            variant="outlined"
            onChange={editSpeaker}
          />
          <FormGroup>
            <FormControlLabel
              control={<Switch />}
              label="Voting"
              onChange={editVoting}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} variant="contained">
            Create Agenda Item
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
