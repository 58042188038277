import {
  Box,
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@mui/material'
import { IAgendaItem } from '../contexts/AGMProvider'
import { AgendaItemVotingResult } from './AgendaItemVotingResult'

export const AgendaItem = ({
  agendaItem,
  attendees,
  setFocus,
}: {
  agendaItem: IAgendaItem
  attendees: any[]
  setFocus: (id: number) => void
}): any => (
  <Card sx={{ m: 2 }}>
    <CardHeader title={agendaItem.topic} subheader={agendaItem.speaker} />
    <CardContent>
      <Box sx={{ mb: 1 }}>
        <Typography variant="body2">Description</Typography>
        <Typography variant="body1">{agendaItem.description}</Typography>
      </Box>
      <Box sx={{ mb: 1 }}>
        <Typography variant="body2">Voting required:</Typography>
        <Typography variant="body1">
          {agendaItem.agendaVote ? 'yes' : 'no'}
        </Typography>
      </Box>
    </CardContent>
    <CardActions>
      <Button onClick={() => setFocus(agendaItem.id)}>Set Focus</Button>
    </CardActions>
    {
      agendaItem.agendaVote &&
        <CardContent>
          <AgendaItemVotingResult max={attendees.length}result={agendaItem.votes}/>
        </CardContent>
    }
  </Card>
)
