import { IAgendaItem } from '../contexts/AGMProvider'
import { AgendaItem } from './AgendaItem'

export const AgendaItemsList = ({
  agendaItems,
  setFocus,
  attendees,
}: {
  agendaItems: IAgendaItem[]
  setFocus: (id: number) => void
  attendees: any[]
}): any =>
  agendaItems.map((agendaItem: any, i: number) => (
    <AgendaItem attendees={attendees } agendaItem={agendaItem} key={i} setFocus={setFocus} />
  ))
