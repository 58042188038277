import { useContext } from 'react'
import { Box, List, Typography } from '@mui/material'
import { CreateAgendaItemDialog } from './CreateAgendaItem'
import { AgendaItemsList } from './AgendaItemsList'
import { AgmContext, IAgendaItem } from '../contexts/AGMProvider'

export const UpdateMeetingDetails = (): any => {
  const { agendaItems, setAgendaItems, setFocus, attendees } = useContext(AgmContext)

  const addItem = (item: IAgendaItem): void => {
    const newItems = [
      ...agendaItems,
      { ...item, order: agendaItems.length },
    ]
    setAgendaItems(newItems)
  }

  return (
    <Box>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Agenda Items
      </Typography>
      <Box>
        <CreateAgendaItemDialog addItem={addItem} />
      </Box>
      <List sx={{ width: '100%', maxWidth: 600, flexDirection: 'column' }}>
        <AgendaItemsList attendees={attendees} agendaItems={agendaItems} setFocus={setFocus} />
      </List>
    </Box>
  )
}
