import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Paper, Box, Container, Typography, Button, Grid } from '@mui/material'

import { Attendees } from './Attendees'
import { UpdateMeetingDetails } from './UpdateMeetingDetails'
import { AgmContext } from '../contexts/AGMProvider'

export const AdminPage = (): any => {
  const { submit, sendEmails, agmId, setAgmId, focusItem } = useContext(AgmContext)
  const { agmIdParam = 0 } = useParams()
  setAgmId(+agmIdParam)

  return (
    <Container>
      <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>AGM Meeting Admin</Typography>
          </Grid>
          <Grid item xs={12}>
            <UpdateMeetingDetails />
            <Attendees />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={() => submit()}>
              Save
            </Button>
            <Button variant="contained" onClick={() => sendEmails()}>
              Send Emails
            </Button>
          </Grid>
        </Grid>
    </Container>
  )
}
