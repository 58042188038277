import { Box, List, ListItem, Typography } from '@mui/material'
import { IAgendaItem } from '../contexts/AGMProvider'
import { VoteOnItem } from './VoteOnItem'

export const AgendaItemReader = ({
  agendaItem,
  attendeeId,
  agmId,
}: {
  agendaItem: IAgendaItem
  attendeeId: number
  agmId: number
}): any => (
  <ListItem alignItems="flex-start" sx={{ 'flex-direction': 'column' }}>
    <Typography variant="h3">{agendaItem.topic}</Typography>
    <Typography variant="h5">{agendaItem.speaker}</Typography>
    <List>
      <ListItem sx={{ 'flex-direction': 'column' }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h4">Description</Typography>
          <Typography variant="body2">{agendaItem.description}</Typography>
        </Box>
      </ListItem>
      <ListItem>
        <Typography variant="body2">
          Voting required: {agendaItem.agendaVote ? 'yes' : 'no'}
        </Typography>
        {agendaItem.agendaVote &&
          <VoteOnItem agendaVoteId={agendaItem.agendaVote?.id ?? 0} attendeeId={attendeeId} agmId={agmId}/>
        }
      </ListItem>
    </List>
  </ListItem>
)
