import { useEffect, useState, createContext, useContext } from 'react'
import { uniqBy, pick } from 'ramda'
import { WebsocketContext } from './WebsocketProvider'

export const AgmContext = createContext({
  agmId: -1,
  attendees: [] as Attendee[],
  agendaItems: [] as IAgendaItem[],
  addAttendee: (_email: string[]): void => {},
  setAgendaItems: (agendaItems: IAgendaItem[]): void => {},
  setFocus: (id: number): void => {},
  setAgmId: (id: number): void => {},
  submit: (): void => {},
  sendEmails: (): void => {},
  focusItem: 0,
  name: '',
  startDateTime: '',
})

interface Attendee {
  email: string
}

export interface IAgendaItem {
  agendaVote?: {
    id: number
  }
  topic: string
  description: string
  speaker: string
  voting: boolean
  id: number
  order: number
  votes?: {
    support?: number
    oppose?: number
    abstain?: number
  }
}

const filterUniqueAttendees = uniqBy((item: Attendee) => item?.email)

export const AgmProvider = ({ children }: any): any => {
  const { sendMessage, messages } = useContext(WebsocketContext)
  const [attendees, setAttendees] = useState([] as Attendee[])
  const [lastProcessed, setLastProcessed] = useState(-1)
  const [focusItem, setFocusItem] = useState(0)
  const [startDateTime, setStartDateTime] = useState('')
  const [name, setName] = useState('')
  const [agmId, setAgmId] = useState(-1)
  const [agendaItems, setAgendaItems] = useState([] as IAgendaItem[])

  const addAttendee = (emails: string[]): void => {
    const newlist = filterUniqueAttendees([
      ...attendees,
      ...emails.map((email: string) => ({ email })),
    ])

    setAttendees(newlist)
  }

  const setFocus = (id: number): void => {
    console.log('got focus request', id)
    setFocusItem(id)
  }

  useEffect(() => {
    if (agmId > 0) { sendMessage(JSON.stringify({ type: 'identify', agmId })) }
  }, [agmId])

  const submit = (): void => {
    sendMessage(
      JSON.stringify({
        type: 'updateAgm',
        agmId,
        attendees,
        name,
        startDateTime,
        focusedItemId: focusItem,
        agendaItems: agendaItems.map(x => ({ ...x, agmId })),
      }),
    )
  }

  const sendEmails = (): void => {
    sendMessage(
      JSON.stringify({
        type: 'email',
        agmId,
      }),
    )
  }

  const createAgmSuccess = (x: any): void => {
    // @ts-expect-error
    window.location = `/agm/${x.id}/Admin`
  }

  const identifySuccess = (x: any): void => {
    // setAgmId(x.id)
    setName(x.agm.name)
    setAttendees(x.agm.attendees)
    setStartDateTime(x.agm.date)
    setAgendaItems(x.agm.agendaItems)
    if (x.agm.focusedItemId) {
      console.log('reset focus item', x.agm.focusedItemId)
      setFocusItem(x.agm.focusedItemId)
    }
  }

  const voteUpdated = (x: any): void => {
    const aI = [...agendaItems].map((item: any) => {
      if (item?.agendaVote?.id !== x.agendaVoteId) return item
      return {
        ...item,
        votes: pick(['support', 'abstain', 'oppose'], x),
      }
    })

    setAgendaItems(aI)
  }

  const processor = (msg: any): void => {
    if (!msg) return
    const handlers = { createAgmSuccess, identifySuccess, voteUpdated, agmUpdated: identifySuccess }
    // @ts-expect-error
    const handler = handlers[msg.type]
    handler?.(msg)
  }

  useEffect(() => {
    if (messages.length <= lastProcessed) {
      return
    }

    // this is ugly, dont hate me
    for (let i = lastProcessed + 1; i <= messages.length; i++) {
      // console.log('process this', messages[i - 1])
      processor(messages[i - 1])
    }

    setLastProcessed(messages.length)
  }, [messages])

  return (
    <AgmContext.Provider
      value={{
        attendees,
        addAttendee,
        submit,
        sendEmails,
        agendaItems,
        setAgendaItems,
        focusItem,
        setFocus,
        startDateTime,
        setAgmId,
        name,
        agmId,
      }}>
      {children}
    </AgmContext.Provider>
  )
}
