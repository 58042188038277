import { Routes, Route } from 'react-router-dom'

import { HomePage } from './components/HomePage'
import { AdminPage } from './components/AdminPage'
import { AttendeePage } from './components/AttendeePage'

export const App = (): any => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/agm/:agmIdParam/admin" element={<AdminPage />} />
      <Route
        path="/agm/:agmIdParam/attendee/:attendeeId"
        element={<AttendeePage />}
      />
    </Routes>
  )
}

export default App
