import * as React from 'react'
import * as ReactDOM from 'react-dom/client'

import App from './App'
import { ThemeProvider } from '@emotion/react'
import { theme } from './style/theme/theme'
import { WebsocketProvider } from './contexts/WebsocketProvider'
import { AgmProvider } from './contexts/AGMProvider'
import { BrowserRouter } from 'react-router-dom'

// @ts-expect-error
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <WebsocketProvider>
          <AgmProvider>
            <App />
          </AgmProvider>
        </WebsocketProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ThemeProvider>,
)
