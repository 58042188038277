import * as React from 'react'

import { Container, Paper, Typography, Grid } from '@mui/material'
import { CreateAgm } from './CreateAgm'

export const HomePage = (): any => {
  return (<Container>
          <Paper elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h3'}>AGM</Typography>
        </Grid>
        <Grid item xs={12}>
          <CreateAgm />
        </Grid>
      </Grid>
  </Paper></Container>)
}
