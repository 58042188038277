import { useParams } from 'react-router-dom'
import { Box, Paper, Typography, Grid } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { AgmContext, IAgendaItem } from '../contexts/AGMProvider'
import { AgendaItemReader } from './AgendaItemReader'

export const AttendeePage = (): any => {
  const { agmIdParam = 0, attendeeId } = useParams()

  const { agendaItems, focusItem, agmId, setAgmId } = useContext(AgmContext)

  useEffect(() => {
    setAgmId(+agmIdParam)
  }
  , [agmIdParam])

  const [item, setItem] = useState(null as IAgendaItem | null)
  useEffect(() => {
    const item = agendaItems.find((aI) => aI.id === focusItem)
    item && setItem(item)
  }, [focusItem, agendaItems])

  return (
    <Paper>
      <Box>
        <Typography variant={'h1'}>AGM Meeting Attendee</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p>AGM Id {agmId} </p>
          <p>Attendee Id {attendeeId} </p>
          <p>Focus item id {focusItem} </p>
          {item && attendeeId
            ? (
            <AgendaItemReader agendaItem={item} attendeeId={+attendeeId} agmId={agmId}/>
              )
            : (
                ''
              )}
        </Grid>
      </Grid>
    </Paper>
  )
}
