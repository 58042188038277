import { useState, useContext, useRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { WebsocketContext } from '../contexts/WebsocketProvider'

const options = ['support', 'oppose', 'abstain']

export interface ConfirmationDialogRawProps {
  id: string
  keepMounted: boolean
  value?: string
  open: boolean
  attendeeId: number
  agendaVoteId: number
  agmId: number
  onClose: (voted: boolean, value?: string) => void
}

const ConfirmationDialogRaw = (props: ConfirmationDialogRawProps) => {
  const { sendMessage } = useContext(WebsocketContext)
  const {
    onClose,
    value: valueProp,
    open,
    agendaVoteId,
    attendeeId,
    agmId,
    ...other
  } = props
  const [value, setValue] = useState(valueProp)
  const radioGroupRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (!open) {
      setValue(valueProp)
    }
  }, [valueProp, open])

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const handleCancel = () => {
    onClose(false)
  }

  const handleSubmitVote = () => {
    const data = {
      type: 'castVoteOnAgendaItem',
      vote: value,
      attendeeId,
      agendaVoteId,
      agm: { id: agmId },
    }
    console.log(data)
    sendMessage(JSON.stringify(data))
    onClose(true, value)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}>
      <DialogTitle>How do you vote?</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}>
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Close
        </Button>
        <Button onClick={handleSubmitVote}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

interface CastVoteProps {
  votedOption?: string
  attendeeId: number
  agendaVoteId: number
  agmId: number
}

export const VoteOnItem = (props: CastVoteProps) => {
  const [open, setOpen] = useState(false)
  const [votedOption, setVotedOption] = useState(props.votedOption)
  const { attendeeId, agendaVoteId, agmId } = props

  const handleClickVoteOnItem = () => {
    setOpen(true)
  }

  const handleClose = (voted: boolean, newValue?: string) => {
    setOpen(false)

    if (voted && newValue) {
      setVotedOption(newValue)
    }
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {votedOption ? <div>You: {votedOption}</div> : ''}
      <Button onClick={handleClickVoteOnItem}>Vote on Item</Button>
      <ConfirmationDialogRaw
      agmId={agmId}
        attendeeId={attendeeId}
        agendaVoteId={agendaVoteId}
        keepMounted={false}
        id="vote-on-item-dialog"
        open={open}
        onClose={handleClose}
        value={votedOption}
      />
    </Box>
  )
}
