import { useState, useContext } from 'react'
import { Grid, TextareaAutosize, Typography, Button } from '@mui/material'
import { AgmContext } from '../contexts/AGMProvider'

const sample =
  'MJ Meyer (BoardPro) <mj@boardpro.io>; Abdul Rehman Gill <abdulrehman@boardpro.io>; Eugene Tsang (BoardPro) <eugene@boardpro.io>; Thomas Talsma (BoardPro) <thomas@boardpro.io>; Thomas Hemmings (BoardPro) <thomas.hemmings@boardpro.com>; Evan Liu <evan@boardpro.io>'

const angleBracketContents = /<([^;]*)>/
const parser = (x: string): string[] => {
  const attendees = x.split(';')
  const emails = attendees.map((a: string): string => {
    if (a.includes('<') && a.includes('>')) {
      const matched = a.match(angleBracketContents)
      return matched?.[1] ?? a
    }

    return a
  })

  return emails
}

export const Attendees = (): any => {
  const [input, setInput] = useState(sample)
  const [editMode, setEditMode] = useState(true)
  const { attendees, addAttendee } = useContext(AgmContext)

  const updateInput = (e: any): void => {
    setInput(e.target.value)
  }

  const showEdit = (): void => setEditMode(attendees.length === 0)

  const formatInput = (): void => {
    const parsed = parser(input)
    addAttendee(parsed)
    setEditMode(false)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={'h2'}>Attendees</Typography>
      </Grid>
      <Grid item xs={12}>
        {/* <p>{sample}</p> */}
        {editMode
          ? (
          <TextareaAutosize
            onChange={updateInput}
            minRows={10}
            aria-label="maximum height"
            placeholder="Paste attendees here"
            defaultValue={sample}
            style={{ width: '100%' }}
          />
            )
          : (
          <p>{JSON.stringify(attendees)}</p>
            )}
        <Button onClick={formatInput}>Save</Button>
        <Button onClick={showEdit}>Edit</Button>
      </Grid>
    </Grid>
  )
}
