import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string
    }
  }
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#005de5' },
    secondary: { main: '#029d70' },
    error: { main: '#eb2c4f' },
    warning: { main: '#ffa420' },
    info: { main: '#0090ff' },
    success: { main: '#029d70' },
    text: {
      primary: '#172333',
      secondary: 'rgba(23, 35, 51, 0.64)',
      disabled: 'rgba(23, 35, 51, 0.46)',
    },
    background: { default: '#ecf0f5', paper: '#ffffff' },
    divider: 'rgba(23, 35, 51, 0.16)',
    action: { disabled: 'rgba(23, 35, 51, 0.46)' },
  },
})
