import { Box, Typography, Grid } from '@mui/material'

const VoteCount = ({ result }: any) => {
  const count = (result?.support ?? 0) + (result?.oppose ?? 0) + (result?.abstain ?? 0)
  return <>{count}</>
}

export const AgendaItemVotingResult = ({ result, max }: any): any => (
  <Box bgcolor="info.main" color="#ffffff" sx={{ p: 2 }}>
    <Box>
      <Typography variant="h4">Voting Results</Typography>
      <Typography variant="body1"><VoteCount result={result}/> / {max} voted</Typography>
    </Box>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body1" color="success.main">
            Support
          </Typography>
          <Typography variant="h3">
            {result?.support ?? 0}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" color="error.main">
            Opposed
          </Typography>
          <Typography variant="h3">
            {result?.oppose ?? 0}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" color="warning.main">
            Abstained
          </Typography>
          <Typography variant="h3">
            {result?.abstain ?? 0}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Box>
)
