import { useState, createContext } from 'react'
import config from '../config'
import useWebSocket from 'react-use-websocket'

export const WebsocketContext = createContext({
  sendMessage: (_x: any): void => {},
  messages: [],
})

export const WebsocketProvider = ({ children }: any): any => {
  const [messages, setMessages] = useState([] as any)
  const { sendMessage } = useWebSocket(config.api, {
    onMessage: (data) => {
      setMessages([...messages, JSON.parse(data.data)])
    },
    onOpen: () => console.log('opened'),
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  })

  return (
    <WebsocketContext.Provider value={{ sendMessage, messages }}>
      {children}
    </WebsocketContext.Provider>
  )
}
