import { useState, useContext } from 'react'
import { Box, TextField, Button, FormGroup, Grid } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { WebsocketContext } from '../contexts/WebsocketProvider'
import { addDays } from 'date-fns'
import enLocale from 'date-fns/locale/en-NZ'

export const CreateAgm = (): any => {
  const { sendMessage } = useContext(WebsocketContext)
  const [name, setName] = useState('')
  const [date, setDate] = useState(addDays(new Date(), 2))
  const [passcode, setPasscode] = useState('')
  const [adminEmail, setAdminEmail] = useState('')
  const nameChange = (e: any): void => {
    setName(e.target.value)
  }

  const dateChange = (newValue: any): void => {
    setDate(newValue)
  }

  const passcodeChange = (e: any): void => {
    setPasscode(e.target.value)
  }

  const adminEmailChange = (e: any): void => {
    setAdminEmail(e.target.value)
  }

  const submit = (): void => {
    const data = { type: 'createAgm', name, date, adminEmail, passcode }
    console.log(data)
    sendMessage(JSON.stringify(data))
  }

  return (
    <Grid container spacing={2} >
      <Grid item xs={6} md={6}>
        <TextField
          id="name"
        fullWidth
          label="Name"
          variant="outlined"
          onChange={nameChange}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
          <DateTimePicker
            label="Start Date Time"
            value={date}
            onChange={dateChange}
            minDate={new Date()}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField
          id="adminEmailInput"
          label="Admin Email"
        fullWidth
          variant="outlined"
          onChange={adminEmailChange}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField
          id="passcode"
        fullWidth
          label="Passcode"
          variant="outlined"
          onChange={passcodeChange}
        />
      </Grid>
      <Grid item xs={6} md={12}>
        <Button variant="contained" onClick={submit}>
          Create New AGM
        </Button>
      </Grid>
    </Grid>
  )
}
